<template>
  <section id="live">
    <b-row class="mb-5">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('Live') }}</h1>
        <h5 class="text-primary">
          {{ $t('liveList') }}
        </h5>
      </b-col>
    </b-row>
    <b-overlay
      v-model="overlay"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end align-items-center">
          <b-button
            variant="primary"
            @click="createNewContent"
          >
            <feather-icon
              icon="RadioIcon"
              class="mr-50"
            />
            <span class="text-nowrap">{{ $t('dataGeneric.create') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <span>
            <strong>{{ categorieData.length != 0 ? categorieData.length : contentsData.length }}</strong> {{
              $t('contents.elements') }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-end">
        <b-col>
          <b-tabs
            pills
            lazy
          >
            <!-- GRID -->
            <b-tab>
              <template #title>
                <feather-icon icon="GridIcon" />
                <span>{{ $t('Cards') }}</span>
              </template>
              <b-overlay
                variant="transparent"
                :show="overlay"
                :opacity="1.00"
                :blur="'2px'"
                rounded="lg"
              >
                <!-- CONTENIDOS -->
                <div>
                  <b-row v-if="contentsData.length != 0">
                    <b-col
                      v-for="(folder, index) in contentsData"
                      :id="folder.node.id"
                      :key="folder.node.id"
                      class="mt-5 "
                      md="6"
                      lg="3"
                      xl="3"
                      sm="12"
                      @click.stop.prevent="editContent(folder.node.id)"
                    >
                      <b-card
                        class="carCat"
                        no-body
                      >
                        <b-img
                          :src="buildImageUrl(folder)"
                          class="card-img-top cursor-pointer"
                          img-alt="Card image"
                          fluid-grow
                          @error="errorImg"
                        />
                        <b-card-header>
                          <div id="acortarPadre">
                            <b-card-title
                              id="acortar"
                              class="mb-25"
                            >
                              <span
                                v-if="folder.node.state == 'GRE'"
                                class="mr-50 bullet bullet-success bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'YEW'"
                                class="mr-50 bullet bullet-warning bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'RED'"
                                class="mr-50 bullet bullet-danger bullet-sm"
                              />
                              <span
                                v-if="folder.node.state == 'BCK'"
                                class="mr-50 bullet bullet-dark bullet-sm"
                              />
                              {{ folder.node.name }}
                            </b-card-title>
                            <b-card-text class="mb-0">
                              {{ $t('DIR') }}
                            </b-card-text>
                          </div>
                          <!-- Incluyo imagenes del tipo de directo -->
                          <div class="mr-2">
                            <b-img
                              v-if="folder.typeStream === 'WOW'"
                              :src="require('@/assets/images/backend/streaming/wowza.png')"
                              img-alt="Wowza Streaming Engine logo"
                              class="image-stream-type"
                            />
                            <b-img
                              v-if="folder.typeStream === 'AWS'"
                              :src="require('@/assets/images/backend/streaming/aws.png')"
                              img-alt="AWS Streaming logo"
                              class="image-stream-type"
                            />
                          </div>
                        </b-card-header>

                        <b-card-body>
                          <div class="actionsCont">
                            <div class="ml-1">
                              <feather-icon
                                v-if="folder.node.state == 'GRE'"
                                v-b-tooltip.hover.top.v-primary="released(folder.node)
                                  ? $t('contVis')
                                  : $t('contOc')
                                "
                                class="text-white"
                                :icon="released(folder.node) ? 'EyeIcon' : 'EyeOffIcon'"
                              />
                              <!-- Etiqueta estado del directo (STR (en directo)) -->
                              <div v-if="folder.stateStream === 'STR'">
                                <span class="badge badge-danger">{{ $t("live.live") }}</span>
                              </div>
                            </div>
                            <div class="action">
                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                right
                              >
                                <template #button-content>
                                  <feather-icon
                                    color="white"
                                    icon="MoreVerticalIcon"
                                    size="20"
                                  />
                                </template>

                                <b-dropdown-item @click.stop.prevent="modalContent(folder.node)">
                                  <feather-icon
                                    icon="FolderPlusIcon"
                                    size="18"
                                  />
                                  {{ $t('addCategory') }}
                                </b-dropdown-item>

                                <!-- Acción iniciar directo en el servidor externo (solo si es AWS o de Wowza y estado es STP (parado)) -->
                                <b-dropdown-item
                                  v-if="['AWS', 'WOW'].includes(folder.typeStream) && folder.stateStream === 'STP'"
                                  @click.stop.prevent="startLive(folder.node.id, index)"
                                >
                                  <feather-icon
                                    icon="PlayCircleIcon"
                                    size="18"
                                  />
                                  {{ $t("live.startLive.name") }}
                                </b-dropdown-item>

                                <!-- Acción parar directo en el servidor externo (solo si es AWS o de Wowza y estado es STR (en directo)) -->
                                <b-dropdown-item
                                  v-if="['AWS', 'WOW'].includes(folder.typeStream) && folder.stateStream === 'STR'"
                                  @click.stop.prevent="stopLive(folder.node.id, index)"
                                >
                                  <feather-icon
                                    icon="PauseCircleIcon"
                                    size="18"
                                  />
                                  {{ $t("live.stopLive.name") }}
                                </b-dropdown-item>

                                <!-- Acción borrar directo en el servidor externo (solo si es AWS o de Wowza y estado es STP (parado)) -->
                                <!-- <b-dropdown-item
                                    v-if="['AWS', 'WOW'].includes(folder.typeStream) && folder.stateStream === 'STP'"
                                    @click.stop.prevent="deleteLive(folder.node.id)">
                                  <feather-icon icon="XCircleIcon" size="18" />
                                  {{ $t("live.deleteLive.name") }}
                                </b-dropdown-item> -->

                                <b-dropdown-item @click.stop.prevent="editContent(folder.node.id)">
                                  <feather-icon
                                    icon="Edit2Icon"
                                    size="18"
                                  />
                                  {{ $t("contents.editContent") }}
                                </b-dropdown-item>

                                <b-dropdown-item
                                  v-if="(folder.stateStream !== 'STR' && userData.isSuperuser) || !['AWS', 'WOW'].includes(folder.typeStream)"
                                  @click.stop.prevent="deleteLives(folder.node.id)"
                                >
                                  <feather-icon
                                    icon="DeleteIcon"
                                    size="18"
                                  />
                                  {{ $t('dataGeneric.delete') }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-overlay>
            </b-tab>
            <!-- TABLE -->
            <b-tab>
              <template #title>
                <feather-icon icon="ListIcon" />
                <span>{{ $t('List') }}</span>
              </template>
              <!-- CONTENIDOS TABLA -->
              <b-card>
                <b-table-simple
                  ref="refContentListTable"
                  class="mt-2 mb-1"
                  show-empty
                  hover
                  selectable
                  :select-mode="single"
                  :empty-text="$t('emptyText')"
                  responsive
                  primary-key="id"
                  :sticky-header="true"
                  :no-border-collapse="true"
                >
                  <b-thead>
                    <b-tr>
                      <b-th
                        v-for="i in tableColumns"
                        :key="i.key"
                      >
                        {{ i.label }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <draggable
                    v-model="contentsData"
                    tag="tbody"
                    handle=".handle"
                  >
                    <b-tr
                      v-for="(item, index) in contentsData"
                      :key="item.node.id"
                    >
                      <b-td @click="editContent(item.node.id)">
                        <div
                          class="d-flex"
                          style="align-items: center"
                        >
                          <b-img
                            id="avatar"
                            class="mr-2 ml-2"
                            rounded="lg"
                            :src="buildImageUrl(item)"
                            @error="errorImg"
                          />
                          <p>{{ item.node.name }}</p>
                        </div>
                      </b-td>
                      <b-td @click="editContent(item.node.id)">
                        <span
                          v-if="item.node.state == 'GRE'"
                          class="text-success"
                        >{{ $t('ecommerce.public') }}</span>
                        <span
                          v-if="item.node.state == 'YEW'"
                          class="text-warning"
                        >{{ $t('dataGeneric.internal')
                        }}</span>
                        <span
                          v-if="item.node.state == 'RED'"
                          class="text-danger"
                        >{{ $t('ecommerce.NoPublic') }}</span>
                        <span
                          v-if="item.node.state == 'BCK'"
                          class="text-dark"
                        >{{ $t('ecommerce.NoPublic') }}</span>
                      </b-td>
                      <b-td @click="editContent(item.node.id)">
                        {{ fechaFormato(item.node.emisionDate) }}
                      </b-td>
                      <b-td @click="editContent(item.node.id)">
                        {{
                          fechaFormato(item.node.publishDate)
                        }}
                      </b-td>

                      <b-td @click="editContent(item.node.id)">
                        {{
                          fechaFormato(item.node.expirationDate)
                        }}
                      </b-td>
                      <b-td>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>

                          <b-dropdown-item @click.stop.prevent="modalContent(item.node)">
                            <feather-icon
                              icon="FolderPlusIcon"
                              size="18"
                            />
                            {{ $t('addCategory') }}
                          </b-dropdown-item>

                          <!-- Acción (vista tabla/lista) iniciar directo en el servidor externo (solo si es AWS o
                            de Wowza y estado es STP (parado)) -->
                          <b-dropdown-item
                            v-if="['AWS', 'WOW'].includes(item.typeStream) && item.stateStream === 'STP'"
                            @click.stop.prevent="startLive(item.node.id, index)"
                          >
                            <feather-icon
                              icon="PlayCircleIcon"
                              size="18"
                            />
                            {{ $t("live.startLive.name") }}
                          </b-dropdown-item>

                          <!-- Acción (vista tabla/lista) parar directo en el servidor externo (solo si es AWS o
                            de Wowza y estado es STR (en directo)) -->
                          <b-dropdown-item
                            v-if="['AWS', 'WOW'].includes(item.typeStream) && item.stateStream === 'STR'"
                            @click.stop.prevent="stopLive(item.node.id, index)"
                          >
                            <feather-icon
                              icon="StopCircleIcon"
                              size="18"
                            />
                            {{ $t("live.stopLive.name") }}
                          </b-dropdown-item>

                          <!-- Acción (vista tabla/lista) borrar directo en el servidor externo (solo si es AWS o
                            de Wowza y estado es STP (parado)) -->
                          <!-- <b-dropdown-item
                              v-if="['AWS', 'WOW'].includes(item.typeStream) && item.stateStream === 'STP'"
                              @click.stop.prevent="deleteLive(item.node.id)">
                            <feather-icon icon="XCircleIcon" size="18" />
                            {{ $t("live.deleteLive.name") }}
                          </b-dropdown-item> -->

                          <b-dropdown-item @click.stop.prevent="editContent(item.node.id)">
                            <feather-icon
                              icon="Edit2Icon"
                              size="18"
                            />
                            {{ $t("contents.editContent") }}
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="(item.stateStream !== 'STR' && userData.isSuperuser) || !['AWS', 'WOW'].includes(item.typeStream)"
                            @click.stop.prevent="deleteLives(item.node.id)"
                          >
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t('dataGeneric.delete') }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                    </b-tr>
                  </draggable>
                </b-table-simple>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal
      ref="modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :exclude-ids="code"
          :type="true"
          :especial="true"
          :columns="categorySelectorTable"
          @confirm-selection="addToCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BDropdown,
  VBTooltip,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardTitle,
  BImg,
  BCardText,
  BCardBody,
  BOverlay,
  BTableSimple,
  BTr,
  BThead,
  BTd,
  BTh,
} from 'bootstrap-vue'
import Vue from 'vue'
import axios from '@axios'
import draggable from 'vuedraggable'
import {
  messageError, showToast, checkRelationOrder, removeResources, cleanRelationContents, manageLive,
} from '@/store/functions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import CategorySelector from '@/views/common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    draggable,
    CategorySelector,
    BImg,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BOverlay,
    BCol,
    BModal,
    BRow,
    BTableSimple,
    BTr,
    BThead,
    BTd,
    BTh,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      overlay: false,
      brItems: [{ id: null, name: this.$t('contents.start') }],
      single: 'single',
      userData: getUserData(),
      headers: {},
      catContentId: [],
      categorieData: [],
      contentsData: [],
      code: this.$route.params.id,
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('editContent.status') },
        { key: 'emision', label: this.$t('emision') },
        { key: 'date_publication', label: this.$t('contents.datePublication') },
        { key: 'date_final', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'type', label: this.$t('type') },
      ],

      categoryId: null,
      categoryName: null,
      selectContent: [],
      infoContent: [],
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.fetchData()
  },
  methods: {
    addToCategory(cat) {
      /* TODO añadir método para añadir a categorías */
      const { headers } = this

      this.categoryId = cat.node.id
      this.categoryName = cat.node.name
      this.hideModal()

      const data = []
      this.selectContent.forEach(element => {
        data.push(element)
      })

      cat.node.categoryContent.edges.forEach(element => {
        data.push(element.node.id)
      })

      const dataArr = new Set(data)

      const result = [...dataArr]

      axios
        .post('', {
          query: `
         mutation{
          updateCategory(id:"${this.categoryId}",input:{categoryContent:[${result}]}){
            category{
              id
              name
              categoryContent(orderBy:"order,-created_at"){
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)

          checkRelationOrder(this.categoryId).then(() => {

          }).catch(err => {
            console.log(err)
          }) // Creamos nueva relación

          cleanRelationContents(this.selectContent).then(() => {
          }).catch(err => {
            console.log(err)
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('contentsAddToCat')} ${this.categoryName}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.selectContent = []
          this.infoContent = []
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('ErrorContentsAddToCat'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },

    createNewContent() {
      const idActual = this.$route.params.id != null ? this.$route.params.id : null
      this.$router.push({ name: 'live-create', params: { id: idActual } })
    },

    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subcategories-live', params: { id } })
      } else {
        this.$router.push({ name: 'categories-live' })
      }
    },

    fetchData() {
      const { headers } = this

      axios
        .post('', {
          query: `
          {
            allContents(type:"DIR",client:"${this.userData.profile.client.id}") {
              totalCount
              edgeCount  
              edges {
                node {
                  id
                  name
                  emisionDate
                  modifiedAt
                  publishDate
                  expirationDate  
                  isActive
                  order
                  state
                  mediaLocation
                  image
                  imageUrl
                  categories {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                  contentResources(isInitial:true){
                    edges{
                      node{
                        resource {
                          typeStream
                          stateStream
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        }, { headers }).then(response => {
          messageError(response, this)
          const res = response.data.data.allContents.edges
          // Obtengo la información del tipo de directo (typeStream) y el estado del
          //  directo (stateStream) del recurso inicial asociado al contenido y
          //  prevengo excepciones
          res.forEach(element => {
            try {
              const contentResources = element.node.contentResources.edges
              contentResources.forEach(e => {
                // eslint-disable-next-line no-param-reassign
                element.stateStream = e.node.resource?.stateStream || ''
                // eslint-disable-next-line no-param-reassign
                element.typeStream = e.node.resource?.typeStream || ''
              })
            } catch (e) {
              // eslint-disable-next-line no-param-reassign
              element.stateStream = ''
              // eslint-disable-next-line no-param-reassign
              element.typeStream = ''
              console.log(`Error al obtener: ${e}`)
            }
          })
          this.contentsData = res
        })
    },

    updateReactiveStateStream(index, stateStream) {
      Vue.set(this.contentsData[index], 'stateStream', stateStream)
    },

    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    editContent(id) {
      this.$router.push({ name: 'live-edit', params: { id } })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    deleteFolder(id) {
      this.overlay = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                mutation{
                  deleteCategory(id:"${id}") {
                    found
                    deletedId
                  }
                }`,
            })
            .then(res => {
              messageError(res, this)

              this.categorieData = this.categorieData.filter(item => item.node.id !== id)
              this.overlay = false
              showToast(this.$t('success'), 1, this)
            })
            .catch(() => {
              this.overlay = false
              showToast(this.$t('error'), 2, this)
            })
        } else {
          this.overlay = false
        }
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    modalContent(content) {
      if (content != null) {
        this.selectContent.push(content.id)
        this.infoContent.push(content)
      }
      // console.log(this.selectContent);

      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },

    checkStreamingContents(contentResources) {
      return new Promise(resolve => {
        const found = Object.entries(contentResources).some(([, resource]) => {
          if (resource && resource.edges) {
            return resource.edges.some(contentResource => {
              const stateStream = contentResource.node?.contentResources?.edges[0]?.node.resource?.stateStream
              // Si encuentra un stateStream igual a "STR", retorna true para detener la iteración
              return stateStream === 'STR'
            })
          }
          return false
        })
        resolve(found)
      })
    },

    async deleteLives(id = null) {
      this.overlay = true
      const selectContent = []
      if (id != null) selectContent.push(id)

      const confirmDelete = await this.showDeleteConfirmation()
      if (!confirmDelete) {
        this.overlay = false
        return
      }
      const contentResources = await this.fetchContentResources(selectContent)
      if (!contentResources) {
        this.overlay = false
        return
      }

      const checkStreamingResult = await this.checkStreamingContents(contentResources)
      if (checkStreamingResult) {
        showToast(this.$t('notDelLive'), 2, this)
        this.overlay = false
        return
      }
      // Usamos una función de ayuda para manejar cada elemento con await dentro del forEach
      const handleElement = async element => {
        const manageLiveResult = await manageLive(
          element,
          'deleteLive',
          this.$t('live.deleteLive.successMsg'),
          this.$t('live.deleteLive.failMsg'),
          'XCircleIcon',
          this,
        )

        if (manageLiveResult !== true) {
          this.delete([element])
          return
        }

        const resultado = await removeResources(contentResources)
        if (!resultado) {
          this.delete([element])
          return
        }

        const checkExternalContent = await this.checkExternalContent(element)
        if (checkExternalContent) {
          await this.deleteResources(resultado, element)
        } else {
          await this.handleOrphanResources(resultado, element)
        }
      }

      // Iterar sobre selectContent usando forEach y ejecutar la función de ayuda
      await Promise.all(selectContent.map(element => handleElement(element)))
    },

    async showDeleteConfirmation() {
      return this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => result.value)
    },

    async fetchContentResources(selectContent) {
      const { headers } = this

      let query = '{'
      selectContent.forEach((element, i) => {
        query += `cont_${i + 1}: allContents(id:"${element}") {
          edges {
            node {
              id
              name
              contentResources {
                totalCount
                edges {
                  node {
                    id
                    resource {
                      id
                      name
                      typeStream
                      stateStream
                      file {
                        id
                        name
                      }
                      resourceContent {
                        totalCount
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }      
            }
          }
        }`
      })
      query += '}'

      try {
        const result = await axios.post('', { query }, { headers })
        messageError(result, this)
        return result.data.data
      } catch (error) {
        console.log(error)
        return null
      }
    },

    async deleteResources(resultado, element) {
      const { headers } = this

      let query = 'mutation{'
      resultado.id.forEach((el, i) => {
        query += `
          resource_${i + 1}: deleteResource(id:"${el}") {
            found
            deletedId
          }`
      })

      if (resultado.file.length) {
        resultado.file.forEach((elFile, i) => {
          query += `
            file_${i + 1}: deleteFile(id:"${elFile}") {
              found
              deletedId
            }`
        })
      }
      query += '}'

      try {
        const response = await axios.post('', { query }, { headers })
        messageError(response, this)
        this.delete([element])
      } catch (err) {
        console.log(err)
      }
    },

    async handleOrphanResources(resultado, element) {
      const deleteOrphanResources = await this.showDeleteOrphanResourcesConfirmation(resultado.name)
      if (deleteOrphanResources) {
        await this.deleteResources(resultado, element)
      } else {
        this.delete([element])
      }
    },

    async showDeleteOrphanResourcesConfirmation(name) {
      return this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.resourceOrphan', { resourceName: name }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(response => response.value)
    },

    delete(selectContent) {
      this.overlay = true

      const { headers } = this

      let query = 'mutation{'
      selectContent.forEach((element, i) => {
        query += `a_${i
          + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
      })
      query += '}'
      axios
        .post('', {
          query,
        }, { headers })
        .then(r => {
          messageError(r, this)

          selectContent.forEach(element => {
            this.contentsData = this.contentsData.filter(item => item.node.id !== element)
          })
          showToast(this.$t('success'), 1, this)

          this.overlay = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)

          this.overlay = false
        })
    },

    released(data) {
      const inicial = data.publishDate == null ? null : new Date(data.publishDate)
      const final = data.expirationDate == null ? null : new Date(data.expirationDate)
      const dateToCheck = new Date()

      if (inicial == null || inicial < dateToCheck) {
        if (final == null || final > dateToCheck) {
          return true
        }
        return false
      }
      return false
    },

    async checkExternalContent(idContent) {
      const { headers } = this

      try {
        // Obtengo información de recurso inicial del contenido
        const query = `
          query {
            allContents(id:"${idContent}"){
              edges{
                node{
                  contentResources (isInitial:true) {
                    edges{
                      node{
                        resource {
                          id
                          typeStream
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `

        const result = await axios.post('', { query }, { headers })
        const allContents = result.data.data.allContents.edges
        const typeStream = allContents[0]?.node?.contentResources.edges[0]?.node?.resource?.typeStream
        if (['AWS', 'WOW'].includes(typeStream)) {
          return true
        }
        return false
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    // Función para iniciar el directo en un servidor externo (AWS, Wowza, etc)
    startLive(idContent, index) {
      manageLive(
        idContent,
        'startLive',
        this.$t('live.startLive.successMsg'),
        this.$t('live.startLive.failMsg'),
        'PlayCircleIcon',
        this,
      ).then(result => {
        if (result) {
          this.updateReactiveStateStream(index, 'STR')
        }
      })
    },

    // Función para parar el directo en un servidor externo (AWS, Wowza, etc)
    stopLive(idContent, index) {
      manageLive(
        idContent,
        'stopLive',
        this.$t('live.stopLive.successMsg'),
        this.$t('live.stopLive.failMsg'),
        'PauseCircleIcon',
        this,
      ).then(result => {
        if (result) {
          this.updateReactiveStateStream(index, 'STP')
        }
      })
    },

    // Función para borrar el directo en un servidor externo (AWS, Wowza, etc)
    // deleteLive(idContent) {
    //   manageLive(
    //     idContent,
    //     'deleteLive',
    //     this.$t("live.deleteLive.successMsg"),
    //     this.$t("live.deleteLive.failMsg"),
    //     'XCircleIcon',
    //     this
    //   );
    // },
  },
}
</script>
<style lang="scss" scoped>
#live .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#live .card-body {
  padding: 0rem;
}

#live .card-body h4 {
  font-size: 1rem !important;
}

#live .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#live .card-body .actionsCont {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  /* justify-content: flex-end; */
  justify-content: space-between;
}

#live .dropContainer {
  border: 3px dashed;
}

#live .editIcon {
  color: darkcyan;
  margin-right: 10px;
}

#live .deleteIcon {
  color: red;
}

#live .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#live #button-content {
  padding: 0px;
}

#live #acortar {
  /*  width: 170px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  overflow: hidden;
  text-overflow: ellipsis;
}

#live #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#live .carCat img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

.image-stream-type {
  max-width: 50px;
  max-height: 40px;
}
</style>
